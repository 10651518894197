import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import parse from "html-react-parser";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import redirectPages from "../wp-content/seo.js";
import { normalizeUrl } from "../utils/urlHelper.js";

const PolicyTemplate = ({ data }) => {
	const { wpPolicy } = data;
	const { siteUrl } = data.site.siteMetadata;
	const seoData = redirectPages.find((page) => normalizeUrl(page.path) === normalizeUrl(wpPolicy.uri));
	const seo = {
		title: seoData?.title || wpPolicy.title,
		description: seoData?.description || wpPolicy.title,
	}
	return (
		<Layout>
			<GatsbySeo
				title={seo.title}
				description={wpPolicy.description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}${wpPolicy.uri}`,
					title: seo.title,
					description: seo.description,
				}}
			/>

			<Container className="my-6">
				<Row>
					<Col>
						<h1 className="display-4 text-center pb-5">{wpPolicy.title}</h1>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="policy px-3 px-lg-0">{parse(wpPolicy.content)}</div>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default PolicyTemplate;

export const blogData = graphql`
	query ($id: String!) {
		wpPolicy(id: { eq: $id }) {
			title
			content
			uri
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;